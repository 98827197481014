import { UserContext } from "../../contexts/UserContext";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  BellSnoozeIcon,
  ChevronDownIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { client } from "../../utils/Client";
import { Board, Column } from "feathers-backend";
import { DataContext } from "../../contexts/DataContext";
import { AddColumn } from "./Functions";
import KanbanColumn from "./KanbanColumn";
import KanbanCard from "./KanbanCard";
import { ModalContext } from "../../contexts/ModalContext";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Selection } from "@nextui-org/react";
import { AuthContext } from "../../contexts/AuthContext";
import OpportunityFollowup from "../Forms/Opportunity/OpportunityFollowup";
import NewOpportunity from "../Forms/Opportunity/NewOpportunity";
import SelectAssignee from "../Forms/Fields/SelectAssignee";
import TagsFilter from "../TagsFilter";
import OpportunityClose from "../Forms/Opportunity/OpportunityClose";

type KanBanBoardProps = {
  board: Board;
  setBoard: (board: Board) => void;
};

export default function KanbanBoard({ board, setBoard }: KanBanBoardProps) {
  const { me } = useContext(AuthContext);
  const { lang } = useContext(UserContext);
  const { columns, tagsFilter, tags } = useContext(DataContext);
  const { openModal } = useContext(ModalContext);
  const [isDragging, setIsDragging] = useState(false);
  const [showTagLabels, setShowTagLabels] = useState(false);
  const { opportunities } = useContext(DataContext);
  const [usersFilter, setUsersFilter] = useState<string | undefined>(undefined);

  const [searchInput, setSearchInput] = useState("");
  const [filteredColumns, setFilteredColumns] = useState<Column[]>(columns);

  useEffect(() => {
    // Filter the columns that have this boardId
    setFilteredColumns(columns.filter((column) => column.boardId === board._id.toString()));
  }, [columns, board._id.toString()]);

  const filteredOpportunities = useMemo(() => {
    return opportunities
      .filter((op) => {
        if (searchInput) {
          const searchTerm = searchInput.toLowerCase();
          const searchableFields: (keyof typeof op)[] = ["displayName"];
          // Search in meta values
          const doesMetaContainTerm = (meta: Record<string, string | number | boolean>, searchTerm: string) => {
            for (const key in meta) {
              const value = meta[key];
              if (typeof value === "string" && value.toLowerCase().includes(searchTerm)) {
                return true;
              } else if (typeof value === "number" && value.toString().includes(searchTerm)) {
                return true;
              }
            }
            return false;
          };

          const matchesBasicFields = searchableFields.some((field) =>
            op[field as keyof typeof op]?.toString().toLowerCase().includes(searchTerm)
          );

          const matchesMeta = doesMetaContainTerm(op.meta, searchTerm);

          return matchesBasicFields || matchesMeta;
        }
        return true;
      })
      .filter((op) => {
        if (usersFilter) {
          return op.assignedTo === usersFilter || op.assignedTo === undefined || op.assignedTo === null;
        }
        return true;
      })
      .filter((op) => {
        if (Array.from(tagsFilter).length > 0) {
          return op.tags && op.tags.some((tagId) => Array.from(tagsFilter).includes(tagId.toString()));
        }
        return true;
      });
  }, [opportunities, searchInput, usersFilter, tagsFilter]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "rgb(40 37 60 / 10%)" : "transparent",
  });

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 2 * 2,
    margin: `0 0 2px 0`,
    borderRadius: "0.375rem",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const handleDragEnd = ({ draggableId, destination, source }: any) => {
    setIsDragging(false);
    if (!destination) return;

    // Check if the dragged item was dropped in the same place it started
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (destination.droppableId === "followup") {
      const opportunity: any = opportunities.find((op) => op._id === draggableId);
      openModal(lang("Follow up"), <OpportunityFollowup opportunity={opportunity} />);
      return;
    }

    // Find the source column and remove the item from its items array
    const sourceColumn = filteredColumns.find((column: any) => column._id === source.droppableId);
    if (!sourceColumn) return;

    // Find the item.version from the source column
    const item = sourceColumn.items.find((item: any) => item._id === draggableId);
    if (!item) return;
    sourceColumn.items.splice(source.index, 1);

    // Find the destination column and add the item to its items array
    const destinationColumn = filteredColumns.find((column: any) => column._id === destination.droppableId);
    if (destinationColumn) {
      destinationColumn.items.splice(destination.index, 0, { _id: draggableId, version: item.version });
    }

    if (destination.droppableId === "closedWon") {
      client.service("opportunities").patch(draggableId, { status: "closedWon" });
    }

    if (destination.droppableId === "closedLost") {
      client.service("opportunities").patch(draggableId, { status: "closedLost" });
    }

    if (destination.droppableId === "remove") {
      client.service("opportunities").remove(draggableId);
    }

    client.service("opportunities").moveCard({
      draggableId: draggableId,
      destination: destination,
      source: source,
      version: item.version,
    });
  };

  function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const FilterAndSearch = () => {
    return (
      <div className="flex flex-row justify-between gap-3 mb-4">
        {/* Search Bar */}
        <div className="w-full">
          <Input
            classNames={{
              inputWrapper: "py-0 h-5",
            }}
            isClearable
            placeholder={lang("Search")}
            startContent={<MagnifyingGlassIcon className="w-5 h-5" />}
            value={searchInput}
            onClear={() => setSearchInput("")}
            onValueChange={(value) => setSearchInput(value)}
          />
        </div>
        <div className="max-w-[300px] w-full">
          <SelectAssignee
            size="md"
            name="selectUser"
            onChange={(name, value) => setUsersFilter(value ?? undefined)}
            value={usersFilter}
          />
        </div>
        {tags.length > 0 && (
          <div className="flex w-auto min-w-[200px] flex-shrink-0">
            <TagsFilter />
          </div>
        )}
        <div className="flex flex-shrink-0 gap-3">
          <Button
            onPress={() => {
              openModal(lang("Add opportunity"), <NewOpportunity board={board} />);
            }}
            color="primary"
            endContent={<PlusIcon className="w-5 h-5" />}
          >
            {lang("Add opportunity")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-[70vh] ">
      <div>{FilterAndSearch()}</div>
      <div className="flex w-full h-full gap-2 mx-auto">
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          {filteredColumns.map((column: Column, index: number) => (
            <div
              className="column flex h-full flex-col min-w-[12rem] grow-0 w-full bg-content1"
              key={column._id.toString()}
            >
              {/* Column Header Logic can stay in KanBanColumn */}
              <KanbanColumn column={column} board={board} />
              {/* Render Items for the Column */}
              <Droppable droppableId={column._id.toString()}>
                {(provided, snapshot) => (
                  <div
                    className="flex-1 p-2 overflow-y-auto"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {column.items.map((item, i) => {
                      const opportunity = filteredOpportunities.find((op) => op._id.toString() === item._id);
                      return (
                        opportunity && (
                          <Draggable
                            key={opportunity._id.toString()}
                            draggableId={opportunity._id.toString()}
                            index={i}
                          >
                            {(provided: any, snapshot: any) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <KanbanCard
                                  board={board}
                                  showTagLabels={showTagLabels}
                                  setShowTagLabels={setShowTagLabels}
                                  opportunity={opportunity}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
          <div className="flex flex-col min-w-[14rem] h-full grow-0 w-full">
            {me && me.role === "admin" && (
              <div
                onClick={() => AddColumn(board)}
                className="flex justify-center h-12 text-sm cursor-pointer text-placehold hover:text-white"
              >
                <PlusIcon className="w-5 h-5 my-auto" aria-hidden="true" />
                <div className="my-auto font-semibold">Lägg till kolumn</div>
              </div>
            )}
            <div className="relative flex items-center justify-center w-full h-full">
              <div className="sticky top-20 self-start w-full max-w-[18rem] space-y-4">
                <Droppable droppableId={"closedWon"}>
                  {(provided, snapshot) => (
                    <div
                      id="reward"
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      className="flex w-full h-32 border-2 border-dashed rounded-lg border-ash"
                    >
                      <div className="flex mx-auto my-auto text-sm text-body">
                        <HandThumbUpIcon
                          className={"w-6 h-6 my-auto transition-all" + (isDragging ? "  text-success" : "")}
                          aria-hidden="true"
                        />
                      </div>
                      <div className="hidden">{provided.placeholder}</div>
                    </div>
                  )}
                </Droppable>
                <Droppable droppableId={"followup"}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      className="flex h-32 border-2 border-dashed rounded-lg border-ash"
                    >
                      <div className="flex mx-auto my-auto text-sm text-body">
                        <BellSnoozeIcon
                          className={"w-6 h-6 my-auto transition-all" + (isDragging ? " text-warning" : "")}
                          aria-hidden="true"
                        />
                      </div>
                      <div className="hidden">{provided.placeholder}</div>
                    </div>
                  )}
                </Droppable>
                <Droppable droppableId={"closedLost"}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      className="flex h-32 border-2 border-dashed rounded-lg border-ash"
                    >
                      <div className="flex mx-auto my-auto text-sm text-body">
                        <HandThumbDownIcon
                          className={"w-6 h-6 my-auto transition-all" + (isDragging ? " text-danger" : "")}
                          aria-hidden="true"
                        />
                      </div>
                      <div className="hidden">{provided.placeholder}</div>
                    </div>
                  )}
                </Droppable>
                {me.role === "admin" && (
                  <Droppable droppableId={"remove"}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        className="flex h-32 border-2 border-dashed rounded-lg border-ash"
                      >
                        <div className="flex mx-auto my-auto text-sm text-body">
                          <TrashIcon
                            className={"w-6 h-6 my-auto transition-all" + (isDragging ? " text-red-400" : "")}
                            aria-hidden="true"
                          />
                        </div>
                        <div className="hidden">{provided.placeholder}</div>
                      </div>
                    )}
                  </Droppable>
                )}
              </div>
            </div>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
}
